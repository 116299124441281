import { dataState, statusState, filtersState } from '../commonState';
import {
	fetch_items,
	multipurpose_response,
	save_data,
	delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial attribute_groups state
const scopedState = {
	// postsSomeData: {}
};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {};

// actions
const actions = {
	fetch_attribute_groups(storeArgs, payload = {}) {
		return fetch_items(storeArgs, '/attribute-groups', payload);
	},

	fetch_attribute_group(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(
			storeArgs,
			`/attribute-groups/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	save_attribute_group(storeArgs, payload) {
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/attribute-groups`, extendedPayload);
	},

	delete_attribute_group(storeArgs, payload) {
		return delete_item(storeArgs, `/attribute-groups`, payload);
	},

	set_attribute_groups({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_attribute_group({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_attribute_groups_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
