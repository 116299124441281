import { getObjectVal } from '@/helpers';

const itemsFetchSetMixin = {
	methods: {
		initialResponses(settings) {
			const firstAction = settings[0].action;
			if (this[firstAction]) {
				this[firstAction](settings[0].payload).then(() => {
					if (settings.length > 1) {
						this.doResponses(settings);
					}
				});
			}
		},

		doResponses(settingsList) {
			for (let i = 1; i < settingsList.length; i++) {
				const { action, payload, bindTo, clean_up } = settingsList[i];

				if (bindTo) {
					const { prop, param, fetch_once } = bindTo;
					const bindingValue = getObjectVal(this, prop, { withoutDeep: true });
					let settings = {};

					if (fetch_once) {
						settings.fetch_once = true;
					}

					this.$watch(prop, n => {
						this.watchHandler(action, payload, param, n, clean_up, settings);
					});

					if (bindingValue) {
						this.watchHandler(
							action,
							payload,
							param,
							bindingValue,
							clean_up,
							settings
						);
					}
				} else if (this[action]) {
					this[action](payload);
				}
			}
		},

		watchHandler(action, payload, param, value, clean_action /*, {fetch_once}*/) {
			const newPayload = {
				...payload,
				params: { ...payload.params }
			};

			if (param) newPayload.params = { ...newPayload.params, [param]: value }

			let isFetch;
			if (value instanceof Array) {
				isFetch = value.length > 0;
			} else if (value) {
				isFetch = true;
			}

			if (isFetch) {
				if (this[action]) {
					this[action](newPayload);
				}
			} else if (this[clean_action]) {
				this[clean_action]();
			}
		},

		cleanLists(settings) {
			for (let item of settings) {
				if (this[item.clean_up]) {
					this[item.clean_up]([]);
				}
			}
		}
	},

	created() {
		// console.log(this.$watch);
		const { initialSetFetchSettings } = this;

		/*this.$watch('formData.vfd_id', (n, old) => {
			console.log(old, n)
		})*/

		if (initialSetFetchSettings && initialSetFetchSettings.length) {
			this.initialResponses(initialSetFetchSettings);
		}
	},

	beforeDestroy() {
		const { initialSetFetchSettings } = this;

		if (initialSetFetchSettings && initialSetFetchSettings.length) {
			this.cleanLists(initialSetFetchSettings);
		}
	}
};

export default itemsFetchSetMixin;
