import isEmpty from 'lodash/isEmpty';
import store from '@/store';
import { isEmptyString } from '@/utils/validate';
import { discountTypesList, deliveryTypesList } from '@/constants/global';

const getList = {
	discountTypesList: () => discountTypesList,
	deliveryTypesList: () => deliveryTypesList,
	categoriesList: () => store.state.categories.itemsList
};

const methodsList = {
	cleanDateString: str => cleanDateString(str)
};

// -----------Getters/Setters-------

const findItemBy = (property, value, itemsList = [], settings = {}) => {
	const { notStrict, returnIndex } = settings;
	let result = null;
	let index;
	if (itemsList.length) {
		for (let i = 0; i < itemsList.length; i++) {
			if (itemsList[i][property]) {
				if (notStrict) {
					if (itemsList[i][property] == value) {
						result = itemsList[i];
						index = i;
						break;
					}
				} else {
					if (itemsList[i][property] === value) {
						result = itemsList[i];
						index = i;
						break;
					}
				}
			}
		}
	}
	return returnIndex ? { item: result, index: index } : result;
};

const setObjectVal = (obj, accessors, val) => {
	let parts = accessors.split('.');
	try {
		for (let i = 0; i < parts.length - 1; i++) {
			obj = obj[parts[i]];
		}

		obj[parts[parts.length - 1]] = val;
	} catch (e) {
		console.warn(e);
	}
};

const getObjectVal = (obj, accessors, settings) => {
	let objCopy = !!settings && !!settings.withoutDeep ? { ...obj } : cloneDeep(obj);
	const parts = accessors.split('.');

	for (let i = 0; i < parts.length; i++) {
		objCopy = objCopy[parts[i]];

		/*if (parts[i] == 'port_number') {
			console.log('getObjectVal: ', objCopy, isEmpty(objCopy))			
		}*/
		if (isEmpty(objCopy)) {
			if (!objCopy) return null;
		}
	}

	return objCopy;
};

const getValues = (keyName, itemsList) => {
	let result = [];

	for (let item of itemsList) {
		if (item[keyName]) {
			result.push(item[keyName]);
		}
	}
	return result;
};

const concatValues = (initialData, additionalData, { props }) => {
	let result = cloneDeep(initialData);

	for (let prop of props) {
		// console.log(result[prop], additionalData[prop])
		if (result[prop]) {
			if (additionalData[prop]) {
				if (result[prop] instanceof Array) {
					result[prop] = mergeArrays(result[prop], additionalData[prop]);
				} else if (result[prop] instanceof Object) {
					result[prop] = { ...result[prop], ...additionalData[prop] };
				} else {
					result[prop] = additionalData[prop];
				}
			}
		} else {
			result[prop] = additionalData[prop];
		}
	}
	return result;
};

const getItemValue = (id, prop, list, index = null) => {
	const item = findItemBy('id', id, list);
	// console.log(item, id, list)
	if (item) {
		const val = getObjectVal(item, prop);
		if (val) {
			return index != undefined ? val[index] : val;
		}
	}
	return '';
};

/*const compareValues = (key, order = 'asc') => {
	return function innerSort(a, b) {
		try {
			if (!a || !b) {return 0;}
			if ( !a.hasOwnProperty(key) || !b.hasOwnProperty(key) ) {
				// property doesn't exist on either object
				return 0;
			}

			const varA = (typeof a[key] === 'string')
				? a[key].toUpperCase() : a[key];
			const varB = (typeof b[key] === 'string')
				? b[key].toUpperCase() : b[key];

			let comparison = 0;
			if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}
			return (
				(order === 'desc') ? (comparison * -1) : comparison
			);
		} catch (e) {console.log(e)}
	};
};*/

const groupBy = (xs, key) => {
	return xs.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};
// -----------Routes/paths--------------
const hasRightsToRoute = route => {
	let result = { hasAccess: true, reason: '' };
	try {
		for (let i = 0; i < route.matched.length; i++) {
			const { meta } = route.matched[i];

			if (!isEmpty(meta)) {
				const { isAuthenticated, authUser, first_loading_app } = store.state.auth;

				if (meta.auth) {
					result.hasAccess = isAuthenticated;
					result.reason = first_loading_app ? 'first_loading' : 'not_auth';
					if (!result.hasAccess) break;
				}

				if (meta.userTypes) {
					result.hasAccess = isAuthenticated
						? meta.userTypes.some(type => authUser.role.type === type)
						: false;
					result.reason = first_loading_app ? 'first_loading' : 'limited_access';

					if (!result.hasAccess) break;
				}
			}
		}
	} catch (e) {
		console.log(e);
	}

	// console.log(result)
	return result;
};

const validateRouteParams = params => {
	if (params.id) {
		// console.log( /^\d+$/.test(params.id))
		return true;
		// return params.id === 'new' || /^\d+$/.test(params.id);
	}
	return false;
};

const getParentPageRoute = (path, steps = 1) => {
	const routeParts = path.split('/');
	let route = '/';

	if (routeParts.length) {
		routeParts.splice(routeParts.length - steps, steps);
		route = routeParts.join('/');
	}
	return route;
};

// -----------Forms--------------
const updateFormData = (itemData, formData, additionalRules = {}) => {
	let item = cloneDeep(itemData);
	let newFormData = {};
	// console.log(itemData)

	for (let prop in formData) {
		// console.log(item[prop])
		if (typeof item[prop] === 'boolean' || item[prop] === 0) {
			// console.log(prop, item[prop])
			newFormData[prop] = item[prop] ? 1 : 0;
		} else if (item[prop]) {
			newFormData[prop] = item[prop];
			// console.log(newFormData[prop])
		} else {
			newFormData[prop] = formData[prop];
		}
	}

	// console.log(newFormData, additionalRules)
	return { ...newFormData, ...additionalRules };
};

const prepareSubmitData = formData => {
	let newFormData = { ...formData };
	// console.log(formData, newFormData);

	let result = {};
	// console.log(newFormData);
	for (let prop in newFormData) {
		// console.log(prop, typeof newFormData[prop]);
		if (newFormData[prop] instanceof Array) {
			if (newFormData[prop].length) {
				result[prop] = newFormData[prop];
			}
		} else if (typeof newFormData[prop] == 'boolean') {
			result[prop] = newFormData[prop];
		} else if (typeof newFormData[prop] == 'object') {
			if (!isEmpty(newFormData[prop])) {
				result[prop] = newFormData[prop];
			}
		} else if (newFormData[prop]) {
			result[prop] = newFormData[prop];
		}
	}
	// console.log(result);
	return result;
};

const prepareUploadData = context => {
	const { ImgUploadBlock } = context.$refs;
	const { uploadBlocksList } = context;
	let data = {};
	if (uploadBlocksList) {
		for (const ref of uploadBlocksList) {
			if (ref.prepareFilesData) {
				data = { ...data, ...ref.prepareFilesData() };
			}
		}
	} else if (ImgUploadBlock) {
		// const { files, mainFile, file } = ImgUploadBlock.prepareFilesData();
		data = { ...ImgUploadBlock.prepareFilesData() };

		// if (files && files.length) {
		// 	data.files = files;
		// }
		// if (mainFile) {
		// 	data.mainFile = mainFile;
		// }
		// if (file) {
		// 	data.file = file;
		// }
	}

	return data;
};

const setupLabel = (item, { accessors, delimeter }) => {
	let label = '';
	for (let i = 0; i < accessors.length; i++) {
		const val = getObjectVal(item, accessors[i]);

		if (val) {
			const delr = i < 2 ? '-' : delimeter;
			label += `${delr} ${val} `;
		}
	}
	return label.slice(2, label.length);
};

const isValid = (value, { method }) => {
	let result = true;

	if (method == 'isEmptyString') {
		result = !isEmptyString(value);
	}

	return result;
};

// ---------------Table-----------
const getCellValue = (row, column) => {
	const { prop, props, meta } = column;
	const emptyText = meta && meta.emptyText ? meta.emptyText : 'нет данных';

	let val = getObjectVal(row, prop);

	if (val && props) {
		let newVal = '';
		for (let propItem of props) {
			newVal += getObjectVal(row, propItem);
			newVal += ' ';
		}
		val = newVal;
	}

	if (meta) {
		if (meta.prepareValue) {
			const { method } = meta.prepareValue;
			val = methodsList[method](val);
		}

		if (meta.getItemValue) {
			const { prop, listName } = meta.getItemValue;
			const list = getList[listName]();
			if (val instanceof Array) {
				let newVal = '';

				for (let i = 0; i < val.length; i++) {
					newVal += `${getItemValue(val[i], prop, list)}${
						i < val.length - 1 ? ', ' : ''
					}`;
				}
				val = newVal;
			} else {
				val = getItemValue(val, prop, list);
			}
		}

		if (meta.fromArray) {
			const { subProp } = meta.fromArray;
			let newVal = '';

			for (let i = 0; i < val.length; i++) {
				let subVal = getObjectVal(val[i], subProp);
				newVal += `<div>${subVal}</div>`;
			}
			val = newVal;
		}

		if (meta.boolean) {
			const { trueVal, falseVal } = meta.boolean;
			if (val instanceof Array) {
				val = val.length ? trueVal : falseVal;
			} else {
				val = val ? trueVal : falseVal;
			}
		}
	}

	// console.log(row, column)

	if (val) {
		return val;
	} else {
		return emptyText;
	}
};

// -------Dates--------
const getPassedTime = sec => {
	if (sec < 60) {
		return `${Math.floor(sec)} seconds`;
	}
	if (sec < 3600) {
		return `${Math.floor(sec / 60)} minutes`;
	}
	if (sec < 3600 * 24) {
		return `${Math.floor(sec / 3600)} hours`;
	}

	return `${Math.floor(sec / (3600 * 24))} days`;
};

const getYmdDateString = ({ dateObj, ms, withTime }) => {
	if (dateObj || ms) {
		const date = dateObj || new Date(ms);
		let y = date.getFullYear();
		let m = date.getMonth() + 1;
		let d = date.getDate();

		m = m < 10 ? `0${m}` : m;
		d = d < 10 ? `0${d}` : d;

		if (withTime) {
			let h = date.getHours();
			let mins = date.getMinutes();
			let s = date.getSeconds();

			h = h < 10 ? `0${h}` : h;
			mins = mins < 10 ? `0${mins}` : mins;
			s = s < 10 ? `0${s}` : s;

			return `${y}-${m}-${d} ${h}:${mins}:${s}`;
		}

		return `${y}-${m}-${d}`;
	}
	return '-';
};

const cleanDateString = str => {
	let result = '';

	if (str) {
		const splited = str.split('T');
		if (splited.length > 1) {
			result += splited[0];

			const secondPart = splited[1].split('.');
			if (secondPart.length > 1) {
				result += ' ' + secondPart[0];
			}
		} else {
			result = str;
		}
	}

	return result;
};

const getDateRange = range_name => {
	const start = new Date();
	const end = new Date();

	switch (range_name) {
		case 'today':
			start.setHours(0, 0, 0);
			break;

		case 'yesterday':
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
			end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
			break;

		case 'last_7_days':
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
			break;

		case 'last_30_days':
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			break;

		case 'this_month':
			// let nextMonth = start.getMonth() + 1;
			start.setDate(1);
			end.setMonth(start.getMonth() + 1);
			end.setDate(0);
			break;

		case 'last_month':
			// let prevMonth = start.getMonth() - 1;
			start.setMonth(start.getMonth() - 1);
			start.setDate(1);
			end.setDate(0);
			break;

		default:
			console.error('no correct range_name');
	}

	return [start, end];
};

const prepareRangeParams = daterange => {
	let payload = { dateStart: '', dateFinish: '' };

	if (daterange && daterange.length) {
		payload.dateStart = daterange[0];
		payload.dateFinish = daterange[1];
		delete payload.daterange;
	}

	return payload;
};

// ------------------
const cloneObj = o => ({ ...o });
const cloneArr = arr => [...arr];
const cloneDeep = item => JSON.parse(JSON.stringify(item));
const removeObjProps = (obj, props) => {
	let newObj = { ...obj };
	for (let i = 0; i < props.length; i++) {
		delete newObj[props[i]];
	}
	return newObj;
};
const cleanObjValues = obj => {
	let newObj = {};
	for (let prop in obj) {
		if (obj[prop] instanceof Array) {
			newObj[prop] = [];
		} else if (obj[prop] instanceof Object) {
			newObj[prop] = {};
		} else {
			newObj[prop] = null;
		}
	}
	return newObj;
};

const mergeArrays = (array1, array2, { duplicateCheckProp }) => {
	let newList = [];

	for (let i = 0; i < array1.length; i++) {
		newList.push(array1[i]);
	}

	for (let j = 0; j < array2.length; j++) {
		if (duplicateCheckProp) {
			if (
				array1.every(i1 => i1[duplicateCheckProp] !== array2[j][duplicateCheckProp])
			) {
				newList.push(array2[j]);
			}
		}
	}
	return newList;
};
const mergeArraysFromObj = (obj, arrayPropName) => {
	let newList = [];

	for (let prop of Object.keys(obj)) {
		obj[prop][arrayPropName].forEach(arrItem => {
			newList.push(arrItem);
		});
	}

	return newList;
};

// ---------------------
const toggleHeightBlock = (open, targetBlock) => {
	open
		? (targetBlock.style.height = targetBlock.firstElementChild.offsetHeight + 'px')
		: (targetBlock.style.height = '0px');
};

const cleanString = str => {
	// console.log(str)
	let result = str.replace(/[,.'"*?#)(]/gi, '');
	result = result.replace(/[\\/|_]/gi, '-');
	return result.toLowerCase();
};

export {
	getList,
	findItemBy,
	setObjectVal,
	getObjectVal,
	getValues,
	concatValues,
	getItemValue,
	groupBy,
	hasRightsToRoute,
	validateRouteParams,
	getParentPageRoute,
	updateFormData,
	prepareSubmitData,
	prepareUploadData,
	setupLabel,
	isValid,
	getCellValue,
	getPassedTime,
	getYmdDateString,
	cleanDateString,
	getDateRange,
	prepareRangeParams,
	cloneObj,
	cloneArr,
	cloneDeep,
	removeObjProps,
	cleanObjValues,
	mergeArrays,
	mergeArraysFromObj,
	toggleHeightBlock,
	cleanString
};
