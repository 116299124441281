<template>
	<div class="search-bar">
		<form class="relative" @submit.prevent="searchSubmit">
			<el-input
				v-model="searchQuery"
				:class="{ hasValue: clearable && !!searchQuery }"
				:suffix-icon="options.suffix"
				placeholder="Искать..."
			>
				<el-button
					v-if="options.prepend"
					slot="prepend"
					native-type="submit"
					icon="icomoon icon-search"
				/>
			</el-input>

			<button
				v-show="clearable && !!searchQuery"
				type="button"
				class="resetButton"
				@click="resetSearch"
			>
				<i class="icomoon icon-cross" />
			</button>
		</form>
	</div>
</template>

<script>
export default {
	props: {
		clearable: Boolean,
		query: String,
		options: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			searchQuery: ''
		};
	},

	created() {
		// console.log('created')
		this.searchQuery = this.query ? this.query : '';
	},
	methods: {
		searchSubmit() {
			if (this.searchQuery !== this.query) {
				// const payload = { filter: 'q', val: this.searchQuery };
				this.$emit('submit', { q: this.searchQuery, page: 1 });
			}
		},

		resetSearch() {
			this.searchQuery = '';
			this.searchSubmit();
		}
	}
};
</script>
