import { dataState, statusState, filtersState } from '../commonState';
import { fetch_items, save_data, delete_item } from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial callbacks state
const scopedState = {
	// postsSomeData: {}
};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {};

// actions
const actions = {
	fetch_callbacks(storeArgs, payload = {}) {
		return fetch_items(storeArgs, '/requests', payload);
	},

	save_callback(storeArgs, payload) {
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/requests`, extendedPayload);
	},

	delete_callback(storeArgs, payload) {
		return delete_item(storeArgs, `/requests`, payload);
	},

	set_callbacks({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_callback({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_callbacks_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
