import { dataState, statusState, filtersState } from '../commonState';
import {
	fetch_items,
	multipurpose_response,
	save_data,
	delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial filters state
const scopedState = {
	// postsSomeData: {}
};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {};

// actions
const actions = {
	fetch_filters(storeArgs, payload = {}) {
		return fetch_items(storeArgs, '/filters', payload);
	},

	fetch_filter(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(
			storeArgs,
			`/filters/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	save_filter(storeArgs, payload) {
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/filters`, extendedPayload);
	},

	delete_filter(storeArgs, payload) {
		return delete_item(storeArgs, `/filters`, payload);
	},

	set_filters({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_filter({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_filters_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
