import { dataState, statusState, filtersState } from '../commonState';
import {
	fetch_items,
	multipurpose_response,
	save_data,
	delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial categories state
const scopedState = {};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {
	categoriesWithoutChildrenOnlyList: ({ itemsList }) => {
		// console.log(itemsList)
		return itemsList.filter(c => c.countChildren < 1);
	},

	categoriesListWithoutCurrent: ({ itemData, itemsList }) => {
		if (itemData && itemData.id) {
			return itemsList.filter(c => c.id !== itemData.id);
		}
		return itemsList;
	}
};

// actions
const actions = {
	fetch_categories(storeArgs, payload = {}) {
		const newPayload = {
			...payload,
			params: {
				...payload.params,
				withChildren: false
			}
		};
		return fetch_items(storeArgs, '/categories', newPayload);
	},

	fetch_category(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(
			storeArgs,
			`/categories/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	save_category(storeArgs, payload) {
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/categories`, extendedPayload);
	},

	delete_category(storeArgs, payload) {
		return delete_item(storeArgs, `/categories`, payload);
	},

	set_categories({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_category({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_categories_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
