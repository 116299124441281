import Vue from 'vue';
import Vuex from 'vuex';

import global from './modules/global';
import auth from './modules/auth';
import users from './modules/users';
import orders from './modules/orders';
import categories from './modules/categories';
import products from './modules/products';
import attributes from './modules/attributes';
import attribute_groups from './modules/attribute_groups';
import discounts from './modules/discounts';
import filters from './modules/filters';
import callback_request from './modules/callback_request';
import reviews from './modules/reviews';
import seo from './modules/seo';
import banner from './modules/banner';
import settings from './modules/settings';
import order_statuses from './modules/order_statuses';
import delivery_regions from './modules/delivery_regions';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		global: global,
		auth: auth,
		users: users,
		orders: orders,
		categories: categories,
		products: products,
		attributes: attributes,
		discounts: discounts,
		attribute_groups: attribute_groups,
		filters: filters,
		callback_request: callback_request,
		reviews: reviews,
		seo: seo,
		banner: banner,
		settings: settings,
		order_statuses: order_statuses,
		delivery_regions: delivery_regions
	}
});
