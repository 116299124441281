import { getDateRange } from '@/helpers';

const logoSrc = '/static/img/top-logo.svg';
const editorAPIKey = 'xgs69xurf1xqz1v4omstjgnsocspeoapq2w26uvwdh2c0tgo';

const USER_TYPES = {
	ADMIN: 1,
	MANAGER: 2,
	CLIENT: 3
};

const userTypesList = [
	{ id: USER_TYPES.ADMIN, name: 'Админ' },
	{ id: USER_TYPES.MANAGER, name: 'Менеджер' },
	{ id: USER_TYPES.CLIENT, name: 'Клиент' }
];

const datePickerShortcuts = [
	{
		text: 'Today',
		onClick(picker) {
			picker.$emit('pick', getDateRange('today'));
		}
	},
	{
		text: 'Yesterday',
		onClick(picker) {
			picker.$emit('pick', getDateRange('yesterday'));
		}
	},
	{
		text: 'Last 7 days',
		onClick(picker) {
			picker.$emit('pick', getDateRange('last_7_days'));
		}
	},
	{
		text: 'Last 30 days',
		onClick(picker) {
			picker.$emit('pick', getDateRange('last_30_days'));
		}
	},
	{
		text: 'This month',
		onClick(picker) {
			picker.$emit('pick', getDateRange('this_month'));
		}
	},
	{
		text: 'Last month',
		onClick(picker) {
			picker.$emit('pick', getDateRange('last_month'));
		}
	}
];

/*const ORDER_STATUSES = {
	NEW: 1,
	ACCEPT: 2,
	CONFIRMED: 3,
	CANCELED: 4
};*/

/*const orderStatusesList = [
	{ id: ORDER_STATUSES.NEW, name: 'новый' },
	{ id: ORDER_STATUSES.ACCEPT, name: 'принят' },
	{ id: ORDER_STATUSES.CONFIRMED, name: 'подтвержден' },
	{ id: ORDER_STATUSES.CANCELED, name: 'отменен' }
];*/

const DELIVERY_TYPES = {
	PICKUP: 2,
	REGION: 3
};

const deliveryTypesList = [
	{ id: DELIVERY_TYPES.PICKUP, title_ru: 'Самовывоз', title_uk: 'Самовивіз' },
	{ id: DELIVERY_TYPES.REGION, title_ru: 'Курьер регион', title_uk: 'Курьер регион' }
];

const PAYMENTS_TYPES = {
	CASH: 1,
	CARD: 2
};

const paymentsTypesList = [
	{ id: PAYMENTS_TYPES.CASH, title_ru: 'Наличными', title_uk: 'Готівкою' },
	{
		id: PAYMENTS_TYPES.CARD,
		title_ru: 'Банковской картой',
		title_uk: 'Банківською карткою'
	}
];

const PAYMENTS_STATUSES = {
	NO_PAYED: 0,
	PAYED: 1
};
const paymentsStatusesList = [
	{ id: PAYMENTS_STATUSES.NO_PAYED, title_ru: 'Не оплачен' },
	{ id: PAYMENTS_STATUSES.PAYED, title_ru: 'Оплачен' },
];

const DISCOUNT_TYPES = {
	PERCENT: 1,
	AMOUNT: 2,
	VALUE: 3
};

const discountTypesList = [
	{ id: DISCOUNT_TYPES.PERCENT, title_ru: 'Процент' },
	{ id: DISCOUNT_TYPES.AMOUNT, title_ru: 'Количество' },
	{ id: DISCOUNT_TYPES.VALUE, title_ru: 'Значение' }
];

const PRODUCT_ACTION_TYPES = {
	HIT: 1,
	NEW: 2,
	DISCOUNT: 3
};

const product_actions = [
	{ id: PRODUCT_ACTION_TYPES.HIT, name: 'ХИТ', className: 'hit', label: 'Хит' },
	{ id: PRODUCT_ACTION_TYPES.NEW, name: 'NEW', className: 'new', label: 'Новинка' }
	// { id: PRODUCT_ACTION_TYPES.DISCOUNT, name: 'скидка', className: 'discount' }
];

const mockCategoriesList = [
	{ id: 1, name: 'Категория 1' },
	{ id: 2, name: 'Категория 2' },
	{ id: 3, name: 'Категория 3' }
];

export {
	logoSrc,
	editorAPIKey,
	USER_TYPES,
	userTypesList,
	datePickerShortcuts,
	// ORDER_STATUSES,
	// orderStatusesList,
	DELIVERY_TYPES,
	deliveryTypesList,
	PAYMENTS_TYPES,
	paymentsTypesList,
	DISCOUNT_TYPES,
	discountTypesList,
	PRODUCT_ACTION_TYPES,
	product_actions,
	mockCategoriesList,
	PAYMENTS_STATUSES,
	paymentsStatusesList
};
