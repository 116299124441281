<template>
	<div class="login-page">
		<div class="bg top" />
		<div class="bg bottom" />

		<div
			class="wrapper wrapper-full-page inlineImg"
			style="background-image: url(/static/img/background/background_login.png)"
		>
			<div class="logo">
				<div class="logo-normal">
					<div class="logo-img">
						<img :src="logoSrc" alt="logo" />
					</div>
				</div>
				<!-- <div class="logo-img">
					<img src="/static/img/top-logo-white.png" alt="logo" />
				</div> -->
				<div class="description">
					<div class="title common-title semi-bold">Войти</div>
					<div class="sub-description ">
						Введите свои данные, чтобы войти в свой аккаунт
					</div>
				</div>
			</div>

			<div class="login-content card">
				<div class="card-header">
					<h2 class="tilte page-title text-center bold">Личный кабинет</h2>
				</div>

				<div class="card-content">
					<form class="standard-form" @submit.prevent="signIn">
						<div class="form-row">
							<el-input
								v-model="formData.email"
								type="email"
								placeholder="Электронная почта"
							>
								<i slot="prefix" class="icomoon icon-envelope" />
							</el-input>
						</div>

						<div class="form-row">
							<el-input
								v-model="formData.password"
								placeholder="пароль"
								type="password"
							>
								<i slot="prefix" class="icomoon icon-lock" />
							</el-input>
						</div>

						<!-- <p-checkbox v-model="form.rememberMe">
								Subscribe to newsletter
								remember me
							</p-checkbox> -->

						<div class="submit-button-container text-center">
							<el-button type="primary" native-type="submit" :loading="isLoading"
								><b>Войти</b></el-button
							>
						</div>
					</form>
				</div>
			</div>

			<!-- <div class="full-page-background"></div> -->
		</div>
	</div>
</template>

<script>
import { logoSrc } from '@/constants/global';

export default {
	data() {
		return {
			formData: {
				email: process.env.NODE_ENV === 'development' ? 'admin@gmail.com' : '',
				password: process.env.NODE_ENV === 'development' ? '123456' : ''
				// email: '',
				// password: ''

				// rememberMe: true
			}
		};
	},

	computed: {
		isLoading() {
			return this.$store.state.auth.isLoading;
		},

		logoSrc() {
			return logoSrc;
		}
	},

	created() {
		this.$store.dispatch('auth/set_data', {
			stateProp: 'first_loading_app',
			val: false
		});
	},

	methods: {
		signIn() {
			this.$store.dispatch('auth/sign_in', { data: this.formData });
		}
	}
};
</script>
