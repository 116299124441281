// import store from '@/store';

// import isEmpty from 'lodash.isempty';
import axios from './axiosService';
import { Notification } from 'element-ui';
import router from '@/router';
import store from '@/store';
import { getObjectVal } from '@/helpers';
// import { setupStatisticsData, setupHistoryData, setupWarningsData, setupTresholdsOurList } from '@/helpers/charts';

const getYmdDateString = ({ dateObj, ms }) => {
	const date = dateObj || new Date(ms);
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	let d = date.getDate();

	m = m < 10 ? `0${m}` : m;
	d = d < 10 ? `0${d}` : d;
	return `${y}-${m}-${d}`;
};

const todayDateRange = () => {
	const today = getYmdDateString({ dateObj: new Date() });
	return [today, today];
};

const lastWeekDateRange = () => {
	let start = new Date();
	let end = getYmdDateString({ dateObj: new Date() });
	// let end = new Date();

	start = getYmdDateString({
		ms: start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
	});
	return [start, end];
};
// -------------------

const combineHeaders = headers => {
	if (headers) return headers;

	return headers;
	// headers = {'Content-Type': 'multipart/form-data'}
};

const setHttpToken = token => {
	if (token) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	} else {
		axios.defaults.headers.common.Authorization = null;
	}

	// console.log('2: ',axios.defaults.headers.common.Authorization)
};

const checkAuthorizationHeaders = () => {
	// console.log('1: ', axios.defaults.headers.common.Authorization)
	if (
		!!axios.defaults.headers.common &&
		!!axios.defaults.headers.common.Authorization
	) {
		// empty
	} else {
		const token = localStorage.getItem('access_token');
		// const token = store.state.auth.access_token;
		setHttpToken(token);
	}
};

const prepareParams = getParams => {
	let result = {};
	for (let prop in getParams) {
		if (getParams[prop] || typeof getParams[prop] === 'boolean') {
			result[prop] = getParams[prop];
		}
	}

	return result;
};

const parsePayload = (payloadData, method) => {
	let result = {};

	// console.log(payloadData)
	if (payloadData) {
		let payload = { ...payloadData };
		let newData = null;
		let newMethod = null;

		if (payload.withFile) {
			try {
				newData = new FormData();

				for (var prop in payload.data) {
					const value = payload.data[prop];
					const isArray = value instanceof Array;
					// console.log('isArray ', prop, payload.data[prop])

					if (isArray) {
						for (let i = 0; i < value.length; i++) {
							newData.append(`${prop}[${i}]`, value[i]);
						}
					} else {
						newData.append(prop, value);
					}
				}

				if (method == 'PUT') {
					newData.set('_method', 'PUT');
				}

				newMethod = newData && newData.has('_method') ? 'POST' : method;

				payload.headers = { 'Content-Type': 'multipart/form-data' };
			} catch (e) {
				console.warn(e);
			}
			// console.log(newData.get('files[]'))
			// console.log(newData.get('title_ru'))
		}

		// if (!isEmpty(payload)) {
		if (payload.params) result.params = prepareParams(payload.params);
		if (payload.data) result.data = newData || payload.data;
		if (payload.url) result.mod_url = payload.url;
		if (payload.headers) result.headers = payload.headers;
		result.newMethod = newMethod || method;
		// }
	}
	// console.log('result', result)
	return result;
};

// ------------------------

const isSuccessStatus = ({ status, data }, withoutDataCheck) => {
	if (status >= 200 && status < 300) {
		// console.log(withoutDataCheck)
		if (withoutDataCheck) {
			return true;
		}

		if (data) {
			if (data.data || data.status || data.id) {
				return true;
			} else if (data.class && data.class == 'deleted') {
				return true;
			} else if (data.status && data.status == 'updated') {
				return true;
			}
		}
	}
	return false;
};

const prepareDataFunctions = {
	prepareCitiesList: cities => {
		// console.time('cityList')
		let itemsList = [];

		for (let i = 0; i < cities.length; i++) {
			const city = cities[i];
			let item = {
				id: city.id,
				description_ru: city.description_ru,
				description_uk: city.description_uk
			};
			itemsList.push(item);
		}
		// console.timeEnd('cityList')

		return itemsList;
	}
};

// --------------------------

const getResponseValue = (response, payload) => {
	let result;
	const { alternateResponseProp, prepareData } = payload;
	if (alternateResponseProp) {
		result = getObjectVal(response, payload.alternateResponseProp, {
			withoutDeep: true
		});
	} else {
		result = response.data.data;
	}
	if (prepareData) {
		result = prepareDataFunctions[prepareData](result);
	}
	return result;
};

const getResponseMessage = originalResponse => {
	let message = '';

	if (originalResponse) {
		const response = originalResponse.data
			? originalResponse.data
			: originalResponse.response;

		if (response && response.data) {
			const { messages } = response.data;

			if (messages instanceof Array) {
				for (let i = 0; i < messages.length; i++) {
					for (let j = 0; j < messages[i].length; j++) {
						const comma = j === messages[i].length - 1 ? '.' : ', ';
						message += `${messages[i][j]}${comma}`;
					}
				}
			}
		}
	}

	return message;
};

const getResultMessage = (resultMessageData, itemData) => {
	let message = '';
	if (resultMessageData) {
		const { is_true, is_false, flag, text } = resultMessageData;
		if (text) {
			message = text;
		} else if (flag && itemData) {
			message = itemData[flag] ? is_true : is_false;
		}
	}

	return message;
};

const handleError = (
	error,
	{ commit, customMessage, reject = null, loading, notify, loadingProp }
) => {
	try {
		const message = getResponseMessage(error);

		if (error.response) {
			if (error.response.status === 401) {
				store.dispatch('auth/clear_auth', { root: true });
				router.push('/login');
				commit('SET_STATUS_LOADING', false);
				commit('SET_STATUS_SAVING', false);

				if (reject) reject(error);
				Notification.warning({
					title: `Not authorized`,
					message: message || 'Try sign in again'
				});
				return;
			}
		}
		// console.log(reject)
		if (reject) {
			reject(error);
		}
		if (loading) {
			if (loadingProp) {
				commit('SET_STATE', { stateProp: loadingProp, value: false });
			} else {
				commit('SET_STATUS_LOADING', false);
				commit('SET_STATUS_SAVING', false);
			}
		}
		if (notify) {
			Notification.error({
				title: `Error`,
				message: customMessage || message || error.message,
				duration: 0
			});
		}
	} catch (e) {
		console.log(e);
	}
};

const getTokenFromUrl = url => {
	const urlSplitted = url.split('token=');
	let token = '';
	// console.log(url)

	if (urlSplitted && urlSplitted.length > 1) {
		const params = urlSplitted[urlSplitted.length - 1].split('&');

		if (params.length) {
			token = params[0];
		} else {
			token = urlSplitted[urlSplitted.length - 1];
		}
	}
	return token;
};

const getParamsFromUrl = url => {
	const urlSplitted = url.split('?');
	let result = [];
	// console.log(url)
	// '?alertTypes=1,2'

	if (urlSplitted && urlSplitted.length > 1) {
		const params = urlSplitted[urlSplitted.length - 1].split('&');

		if (params.length) {
			for (let param of params) {
				const paramSplitted = param.split('=');
				if (paramSplitted.length) {
					const paramValueSplitted = paramSplitted.split(',');
					result.push({ paramName: paramSplitted[0], values: paramValueSplitted });
				}
			}
			result = params[0];
		} else {
			result = urlSplitted[urlSplitted.length - 1];
		}
	}
	return result;
};

const getValuesFromRouteQuery = valuesStr => {
	let result = [];
	const values = valuesStr.split(',');

	for (let value of values) {
		if (isNaN(+value)) {
			result.push(value);
		} else {
			result.push(+value);
		}
	}
	return result;
};

const isPrevent = () => store.state.auth.preventRequests;

export {
	checkAuthorizationHeaders,
	combineHeaders,
	setHttpToken,
	parsePayload,
	getResponseMessage,
	isSuccessStatus,
	handleError,
	isPrevent,
	getResponseValue,
	getYmdDateString,
	todayDateRange,
	lastWeekDateRange,
	getResultMessage,
	getTokenFromUrl,
	getParamsFromUrl,
	getValuesFromRouteQuery
};
