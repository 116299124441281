import { findItemBy } from '@/helpers';
import { discountTypesList } from '@/constants/global';

const setupDiscountMixin = {
	data() {
		return {
			discount_type_id: null
		};
	},

	computed: {
		discountTypesList: () => discountTypesList,
		discountsListByType() {
			return this.$store.getters['discounts/discountsListByType'](
				this.discount_type_id
			);
		}
	},

	methods: {
		setupDiscount(discountsList, formData) {
			if (formData.discount_id) {
				const discountItem = findItemBy('id', formData.discount_id, discountsList);
				const discountTypeItem = findItemBy(
					'id',
					discountItem.type,
					discountTypesList
				);

				this.discount_type_id = discountTypeItem.id;
			}
		}
	}
};

export default setupDiscountMixin;
