import { dataState, statusState, filtersState } from '../commonState';
import {
	fetch_items,
	multipurpose_response,
	save_data,
	delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial discounts state
const scopedState = {};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {
	discountsListByType: ({ itemsList }) => type => {
		// console.log(itemsList)
		return itemsList.filter(d => d.type === type);
	}
};

// actions
const actions = {
	fetch_discounts(storeArgs, payload = {}) {
		return fetch_items(storeArgs, '/discounts', payload);
	},

	fetch_discount(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(
			storeArgs,
			`/discounts/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	save_discount(storeArgs, payload) {
		// console.log(payload)
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/discounts`, extendedPayload);
	},

	delete_discount(storeArgs, payload) {
		return delete_item(storeArgs, `/discounts`, payload);
	},

	set_discounts({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_discount({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_discounts_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
