import {
	combineHeaders,
	checkAuthorizationHeaders,
	parsePayload /*, combineUrl*/
} from './api_helpers';

import axios from './axiosService';

const api = (method, url, payload) => {
	const { headers, data, params, newMethod } = parsePayload(payload, method);
	checkAuthorizationHeaders();
	// console.log('3 ', newMethod, data, combineHeaders(headers) )
	return axios({
		method: newMethod || method,
		url: url,
		headers: combineHeaders(headers),
		params: params,
		data: data
	});
};

export { api };
