// import { dataState, statusState, filtersState, sortingState } from '../commonState';
import { multipurpose_response } from '../commonActions';
import { dataMutations } from '../commonMutations';

/*const itemsModules = [
	{ moduleName: 'companies', action: 'set_companies_filters' },
	// { moduleName: 'plants', action: 'set_plants_filters'},
	{ moduleName: 'controllers', action: 'set_controllers_filters' },
	{ moduleName: 'sensors', action: 'set_sensors_filters' }
];*/

const state = {
	overlay: {
		show: false,
		text: ''
	},
	navbarSettings: {},
	globalFilters: {
		plantId: null,
		companyId: null
	},

	globalPlantsList: [],
	globalPlantsLoading: false,

	isSidebarCollapse: false
};

const mutations = { ...dataMutations };
const getters = {};

// actions
const actions = {
	show_overlay({ commit }, data) {
		const payload = { stateProp: 'overlay', value: data };
		commit('SET_STATE', payload);
	},
	setup_navbar({ commit }, data = {}) {
		const payload = { stateProp: 'navbarSettings', value: data };
		commit('SET_STATE', payload);
	},

	set_global_filters({ commit }, globFilters) {
		const payload = { stateProp: 'globalFilters', value: globFilters };
		commit('SET_STATE', payload);
	},

	fetch_global_plants(storeArgs, payload = {}) {
		const extendedPayload = {
			...payload,
			method: 'GET',
			stateProp: 'globalPlantsList',
			prepareData: 'prepareGlobalPlantsData',
			loadingProp: 'globalPlantsLoading',
			notNotify: true
		};
		return multipurpose_response(storeArgs, `/plants`, extendedPayload);
	},

	set_global_plants({ commit }, items = []) {
		const payload = { stateProp: 'globalPlantsList', value: items };
		commit('SET_STATE', payload);
	},

	/*set_global_filters({ commit, dispatch, rootState }, { setForAll, globFilters, itemsFilters }) {
		const payload = { stateProp: 'globalFilters', value: globFilters };
		commit('SET_STATE', payload);

		if (setForAll) {
			for (let moduleItem of itemsModules) {
				const { moduleName, action } = moduleItem;
				const newItemFilters = { ...rootState[moduleName].filters, ...itemsFilters };
				dispatch(`${moduleName}/${action}`, newItemFilters, { root: true });
			}
		}
	},*/

	minimizeSidebar({ commit, state }) {
		const payload = {
			stateProp: 'isSidebarCollapse',
			value: !state.isSidebarCollapse
		};
		commit('SET_STATE', payload);
	}

	/*emit_submit_item_form({ commit }, data = {}) {
		const payload = { stateProp: 'navbarSettings', value: data };
		commit('SET_STATE', payload);
	}*/
};

export default {
	state,
	getters,
	actions,
	mutations
};
