import store from '@/store';
import { Notification } from 'element-ui';
import { hasRightsToRoute } from '@/helpers';
import { getTokenFromUrl } from '@/services/api/api_helpers';

const nextStep = (to, from, next) => {
	const { hasAccess, reason } = hasRightsToRoute(to);

	if (hasAccess) {
		next();
	} else if (reason === 'limited_access') {
		// next(from.fullPath);
		Notification({
			type: 'warning',
			title: 'Доступ ограничен',
			message: 'у Вас недостаточно прав для просмотра этой страницы'
		});
		next('/login');
		return;
	} else if (reason === 'not_auth') {
		// next({ name: 'Login' });
		next('/login');
		Notification({
			type: 'warning',
			title: 'Доступ ограничен',
			message: 'для просмотра этой страницы необходимо войти под своим аккаунтом'
		});
		return;
	} else {
		next('/login');
		return;
	}
	next();
};

const beforeEach = (to, from, next) => {
	const token = getTokenFromUrl(to.fullPath);
	if (token) {
		store.dispatch('auth/get_auth_user', token).then(() => {
			nextStep(to, from, next);
		});
	} else {
		nextStep(to, from, next);
	}
};

export default beforeEach;
