const refsOperationsMixin = {
	data() {
		return {
			collectedData: []
		};
	},

	methods: {
		getRefsListLength() {
			const { $refs } = this;
			let count = 0;
			for (let refItem of this.refsList) {
				if (this.$refs[refItem]) {
					if ($refs[refItem] instanceof Array) {
						count += $refs[refItem].length;
					} else {
						// console.log($refs[refItem])
						count++;
					}
				}
			}

			return count;
		},

		refsCaller(method) {
			try {
				for (let refItem of this.refsList) {
					// console.log(refItem, this.$refs[refItem])
					if (this.$refs[refItem]) {
						if (this.$refs[refItem] instanceof Array) {
							for (let ref of this.$refs[refItem]) {
								ref[method]();
								// console.log(ref[method])
							}
						} else {
							this.$refs[refItem][method]();
						}
					}
				}
			} catch (e) {
				console.warn(e);
			}
		},

		handleCleanForm() {
			const { cleanMethod } = this.refsOperationsSettings;
			this.refsCaller(cleanMethod);
		},

		handleSaveItem() {
			this.collectedData = [];
			// console.log(this.refsOperationsSettings)
			const { itemSubmitMethod } = this.refsOperationsSettings;
			this.refsCaller(itemSubmitMethod);
		},

		blockReady(payload) {
			this.collectedData.push(payload);
		},

		setupPayload(dataArr) {
			const { dataAsArray, submitSettings } = this.refsOperationsSettings;
			let data = dataAsArray ? [] : {};

			for (let item of dataArr) {
				if (dataAsArray) {
					data.push(item.data);
				} else {
					data = { ...data, ...item.data };
				}
			}

			return { data: data, submitSettings: submitSettings };
		},

		prepareCollectedData(dataArr) {
			const { dataAsArray } = this.refsOperationsSettings;
			let result = {};

			for (let item of dataArr) {
				if (dataAsArray) {
					result[item.formDataProp] = result[item.formDataProp] || [];
					result[item.formDataProp].push(item.data);
				} else {
					result[item.formDataProp] = result[item.formDataProp] || {};
					result[item.formDataProp] = { ...result[item.formDataProp], ...item.data };
				}
			}

			return result;
		}
	},

	watch: {
		collectedData(dataArr) {
			if (!this.refsOperationsSettings.wactherInCompomponent) {
				// console.log(dataArr)
				if (Object.keys(dataArr).length == this.getRefsListLength()) {
					const isValid = dataArr.every(o => o.isValid);

					if (isValid) {
						const preparedData = this.prepareCollectedData(dataArr);

						// console.log('preparedData: ', preparedData);
						const submitActionName =
							this.submitActionName || this.refsOperationsSettings.submitActionName;
						if (submitActionName) {
							this[submitActionName]({ injectToBody: preparedData });
						}
					} else {
						let message = '';

						for (let item of dataArr) {
							if (item.titles) {
								item.titles.forEach(t => (message += `${t}, `));
							}
						}
						// console.log(message)

						setTimeout(() => {
							this.$notify({
								type: 'warning',
								title: 'Validation error',
								message: `${message} - is required`,
								duration: 0
							});
						}, 10);
					}
				}
			}
		}

		/*collectedData(dataArr) {
			if (!this.refsOperationsSettings.wactherInCompomponent) {
				if (Object.keys(dataArr).length == this.getRefsListLength()) {
					const isValid = dataArr.every(o => o.isValid);
					if (isValid) {
						let payload = this.setupPayload(dataArr);
						// console.log(payload);

						const submitActionName =
							this.submitActionName || this.refsOperationsSettings.submitActionName;

						if (submitActionName) {
							this[submitActionName](payload);
						}
					} else {
						let message = '';

						for (let item of dataArr) {
							if (item.titles) {
								item.titles.forEach(t => (message += `${t}, `));
							}
						}
						// console.log(message)

						setTimeout(() => {
							this.$notify({
								type: 'warning',
								title: 'Форма не готова',
								// message: `${message} - необходимое поле`,
								message: `не все необходимые поля заполнены`,
								duration: 0
							});
						}, 10);
					}
				}
			}
		}*/
	}
};

export default refsOperationsMixin;
