// import { findItemBy } from '@/helpers';
import uniqid from 'uniqid';

const createFormItemMixin = {
	computed: {
		validateSubItems: () => true
	},

	methods: {
		setupFormSubItemsList(dataList, uniqidPrefix) {
			const itemsList = [];
			for (const item of dataList) {
				itemsList.push({ id: uniqid(`${uniqidPrefix}-`), data: { ...item } });
			}
			return itemsList;
		},

		addFormItem(prop, uniqPrefix) {
			let newItem = {
				id: uniqid(uniqPrefix),
				new: true,
				data: {}
			};
			this[prop].push(newItem);
		},

		removeFormItem({ id, listName }) {
			this[listName] = this[listName].filter(o => o.id !== id);
		}
	}
};

export default createFormItemMixin;
