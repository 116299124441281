import Login from '@/layout/pages/Login.vue';
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import { USER_TYPES } from '@/constants/global';

const Navbar = () =>
	import(
		/* webpackChunkName: "navbar" */ '@/layout/dashboard/components/TopNavbar.vue'
	);
const Sidebar = () =>
	import(
		/* webpackChunkName: "sidebar" */ '@/layout/dashboard/components/Sidebar/Sidebar.vue'
	);
// const ItemEditNavbar = () => import(/* webpackChunkName: "navbar" */ '@/layout/dashboard/components/ItemEditNavbar.vue');

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/',
		component: DashboardLayout,
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "dashboard" */ '@/layout/dashboard/views/Dashboard/Dashboard.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/orders',
				name: 'Orders',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "orders" */ '@/layout/dashboard/views/Orders/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/categories',
				name: 'Categories',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "categories" */ '@/layout/dashboard/views/Categories/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/products',
				name: 'Products',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "products" */ '@/layout/dashboard/views/Products/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/attribute-groups',
				name: 'AttributeGroups',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "AttributeGroups" */ '@/layout/dashboard/views/AttributeGroups/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/attributes',
				name: 'Attributes',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "attributes" */ '@/layout/dashboard/views/Attributes/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/filters',
				name: 'Filters',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Filters" */ '@/layout/dashboard/views/Filters/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/callback-request',
				name: 'Callback',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Callback" */ '@/layout/dashboard/views/Callback/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/reviews',
				name: 'Reviews',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Reviews" */ '@/layout/dashboard/views/Reviews/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},

			{
				path: '/users',
				name: 'Users',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Users" */ '@/layout/dashboard/views/Users/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/discounts',
				name: 'Discounts',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Discounts" */ '@/layout/dashboard/views/Discounts/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/seo',
				name: 'SEO',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "SEO" */ '@/layout/dashboard/views/SEO/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/banner',
				name: 'Banner',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "Banner" */ '@/layout/dashboard/views/Banner/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/settings',
				name: 'SettingsPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "SettingsPage" */ '@/layout/dashboard/views/Settings/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/order-statuses',
				name: 'OrderStatus',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "OrderStatus" */ '@/layout/dashboard/views/OrderStatuses/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/delivery-regions',
				name: 'DeliveryRegions',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "DeliveryRegions" */ '@/layout/dashboard/views/DeliveryRegions/ItemsList.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},

			// ------------------
			{
				path: '/orders/:id',
				name: 'OrderPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "OrderPage" */ '@/layout/dashboard/views/Orders/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/categories/:id',
				name: 'CategoryPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "CategoryPage" */ '@/layout/dashboard/views/Categories/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/products/:id',
				name: 'ProductPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "ProductPage" */ '@/layout/dashboard/views/Products/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/attributes/:id',
				name: 'AttributePage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "AttributePage" */ '@/layout/dashboard/views/Attributes/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/attribute-groups/:id',
				name: 'AttributeGroupPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "AttributeGroupPage" */ '@/layout/dashboard/views/AttributeGroups/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/filters/:id',
				name: 'FiltersPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "FiltersPage" */ '@/layout/dashboard/views/Filters/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/callback-request/:id',
				name: 'CallbackPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "CallbackPage" */ '@/layout/dashboard/views/Callback/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/reviews/:id',
				name: 'ReviewsPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "ReviewsPage" */ '@/layout/dashboard/views/Reviews/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/users/:id',
				name: 'UsersPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "UsersPage" */ '@/layout/dashboard/views/Users/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/discounts/:id',
				name: 'DiscountsPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "DiscountsPage" */ '@/layout/dashboard/views/Discounts/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/seo/:id',
				name: 'SEOPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "SEOPage" */ '@/layout/dashboard/views/SEO/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/banner/:id',
				name: 'BannerPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "BannerPage" */ '@/layout/dashboard/views/Banner/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/order-statuses/:id',
				name: 'OrderStatusPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "OrderStatusPage" */ '@/layout/dashboard/views/OrderStatuses/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			},
			{
				path: '/delivery-regions/:id',
				name: 'DeliveryRegionPage',
				meta: { auth: true, userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "DeliveryRegionPage" */ '@/layout/dashboard/views/DeliveryRegions/ItemPage.vue'
						),
					navbar: Navbar,
					sidebar: Sidebar
				}
			}
		]
	},

	{
		path: '*',
		name: 'NotFoundPage',
		component: () =>
			import(/* webpackChunkName: "notFound" */ '@/layout/pages/NotFoundPage.vue')
	}
];

export default routes;
