// import { todayDateRange } from '@/services/api/api_helpers';

const dateRangeMixin = {
	methods: {
		handleDateRange(range) {
			let value = range;
			if (range != this.filters.daterange) {
				/*if (!range) {
					value = todayDateRange();
				}*/
				this.set_filters({ ...this.filters, daterange: value });
			}
			// console.log(value, this.filters.daterange)
		}
	}
};

export default dateRangeMixin;
