// import { MessageBox, Message, Notification } from 'element-ui';
import MessageBox from 'element-ui/lib/message-box';
import Message from 'element-ui/lib/message';
import Notification from 'element-ui/lib/notification';

import 'element-ui/lib/theme-chalk/message-box.css';
import 'element-ui/lib/theme-chalk/message.css';
import 'element-ui/lib/theme-chalk/notification.css';

import cyrillicToTranslit from 'cyrillic-to-translit-js';

export default {
	install(Vue /*, installOptions*/) {
		Vue.prototype.$confirm = options => {
			return MessageBox(options);
		};

		Vue.prototype.$message = options => {
			return Message(options);
		};

		Vue.prototype.$notify = options => {
			return Notification(options);
		};

		Vue.prototype.$cyrillic_to_translit = (str, spaceReplacement) => {
			return cyrillicToTranslit().transform(str, spaceReplacement);
		};
		Vue.prototype.$translit_to_cyrillic = (str, spaceReplacement) => {
			return cyrillicToTranslit().reverse(str, spaceReplacement);
		};
	}
};
