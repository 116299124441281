import { dataState, statusState, filtersState } from '../commonState';
import {
	// fetch_items,
	multipurpose_response
	// save_data,
	// delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial settings state
const scopedState = {};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {};

// actions
const actions = {
	fetch_settings(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			loadingProp: 'isLoading',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(storeArgs, `/settings`, extendedPayload);
	},

	save_settings(storeArgs, payload) {
		// console.log(payload)
		const extendedPayload = {
			...payload,
			method: 'PUT',
			stateProp: 'itemData',
			loadingProp: 'isSaving'
		};
		return multipurpose_response(storeArgs, `/settings`, extendedPayload);
	},

	set_settings({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
