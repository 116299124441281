import { Notification } from 'element-ui';
import { statusState } from '../commonState';
import { statusMutations, dataMutations } from '../commonMutations';
import {
	getResponseMessage,
	isSuccessStatus,
	handleError,
	setHttpToken
} from '@/services/api/api_helpers';
import { api } from '@/services/api';
import router from '@/router';

const token = localStorage.getItem('access_token');
const user = JSON.parse(localStorage.getItem('authUser'));
const motorIQLink = localStorage.getItem('motorIQLink');

// auth state
const state = {
	...statusState,
	preventRequests: false,
	authUser: !!token && !!user ? user : null,
	// authUser: null,
	isAuthenticated: !!user || false,
	// isAuthenticated: false,
	access_token: token || null,
	first_loading_app: true,
	motorIQLink: motorIQLink || ''
};

// getters
const getters = {};

// actions
const actions = {
	sign_in({ commit }, payload) {
		commit('SET_STATUS_LOADING', true);

		api('POST', '/auth/login', payload)
			.then(response => {
				if (isSuccessStatus(response)) {
					if (response.data.data.access_token) {
						const { access_token, motorIQ } = response.data.data;
						// commit('AUTH_TOKEN_SUCCESS', { token: access_token, user: user });
						commit('AUTH_TOKEN_SUCCESS', access_token);
						if (motorIQ) {
							localStorage.setItem('motorIQLink', motorIQ.baseUri);
							commit('SET_STATE', {
								stateProp: 'motorIQLink',
								value: motorIQ.baseUri
							});
						}

						router.push('/');
						// Notification.success(`Welcome ${user.full_name}`);
					} else {
						commit('AUTH_CLEAR');
						// toastr.error('Ошибка', message || 'ответ не содержит данных', { timeOut: 0 });
					}
				} else {
					const message = getResponseMessage(response);
					Notification.error({
						title: `Error`,
						message: message || 'wrong response status',
						duration: 0
					});
				}
				commit('SET_STATUS_LOADING', false);
			})
			.catch(error => {
				// console.log(error)
				commit('AUTH_CLEAR');
				handleError(error, { commit: commit, loading: true, notify: true });
			});
	},

	/*remote_authorization({ commit, dispatch }, token) {
		commit('SET_STATUS_LOADING', true);

		commit('SET_STATE', { stateProp: 'access_token', value: token });
		localStorage.setItem('access_token', token);
		

		dispatch('get_auth_user')
			.then(data => {
				
			});			

		api('GET', '/auth/user')
			.then(response => {
				if (isSuccessStatus(response)) {
					if (response.data.data) {
						const { user } = response.data.data;
						commit('SET_STATE', { stateProp: 'authUser', value: user });
					} else {
						commit('AUTH_CLEAR');
					}
				} else {
					const message = getResponseMessage(response);
					Notification.error({ title: `Error`, message: message || 'wrong response status' });
				}
				commit('SET_STATUS_LOADING', false);
			})
			.catch(error => {
				commit('AUTH_CLEAR');
				handleError(error, { commit: commit, customMessage: 'auth user not fetched' });
			});
	},*/

	get_auth_user({ commit }, token) {
		commit('SET_STATUS_LOADING', true);
		let url = '/auth/user';

		if (token) {
			setHttpToken(token);
			commit('SET_STATE', { stateProp: 'access_token', value: token });
			localStorage.setItem('access_token', token);
			url += '?refresh_token=true';
		}

		// dispatch('get_auth_user')

		return new Promise((resolve, reject) => {
			api('GET', url)
				.then(response => {
					// console.log('get_auth_user then');
					if (isSuccessStatus(response)) {
						if (response.data.data) {
							const { user } = response.data.data;
							commit('AUTH_USER_SUCCESS', user);
							// commit('SET_STATE', { stateProp: 'authUser', value: user });
						} else {
							commit('AUTH_CLEAR');
						}
						resolve(response.data);
					} else {
						const message = getResponseMessage(response);
						reject(response);
						Notification.error({
							title: `Error`,
							message: message || 'wrong response status'
						});
					}
					commit('SET_STATUS_LOADING', false);
				})
				.catch(error => {
					// console.log('get_auth_user catch');
					commit('AUTH_CLEAR');
					handleError(error, {
						commit: commit,
						reject: reject,
						customMessage: 'auth user not fetched',
						loading: true
					});
				});
		});
	},

	sign_out({ commit }) {
		commit('SET_STATUS_LOADING', true);
		router.push('/login');
		commit('AUTH_CLEAR');
		setTimeout(() => {
			commit('SET_STATUS_LOADING', false);
			Notification.success({ title: `Success`, message: `Successfuly logout` });
		}, 100);
	},

	clear_auth({ commit }) {
		commit('AUTH_CLEAR');
	},

	set_data({ commit }, data) {
		commit('SET_STATE', data);
	}
};

// mutations
const mutations = {
	...statusMutations,
	...dataMutations,

	AUTH_TOKEN_SUCCESS: (state, token) => {
		localStorage.setItem('access_token', token);
		state.access_token = token;
	},

	AUTH_USER_SUCCESS: (state, user) => {
		localStorage.setItem('authUser', JSON.stringify(user));
		state.preventRequests = false;
		state.authUser = user;
		state.isAuthenticated = true;
	},

	AUTH_CLEAR: state => {
		localStorage.removeItem('access_token');
		localStorage.removeItem('authUser');
		setHttpToken(null);
		state.preventRequests = true;
		state.isAuthenticated = false;
		state.access_token = null;
		state.authUser = null;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
