import { validateRouteParams /*, getParentPageRoute*/ } from '@/helpers';

const initPageDataMixin = {
	data() {
		return {
			loadContent: false
		};
	},

	methods: {
		fetchPageData(id, options) {
			this.fetch_item({ itemId: id, notNotifyError: true, ...options })
				.then(() => {
					// console.log('ok')
					this.loadContent = true;
				})
				.catch(error => {
					if (error.response.status === 404) {
						this.$router.push('/');
						setTimeout(() => {
							this.$notify({
								type: 'warning',
								title: 'Redirect',
								message: `${this.itemsName.one} с псевдонимом "${id}" не найден`
							});
						}, 200);
					}
				});
		}
	},

	// watch: {},

	created() {
		this.setup_navbar(this.navbarSettings);
	},

	mounted() {
		// console.log(this.$route.params.id)
		const { params } = this.$route;

		if (validateRouteParams(params)) {
			if (params.id === 'new') {
				this.loadContent = true;
			} else {
				// console.log(params.id)
				this.fetchPageData(params.id, { notNotify: true });
			}
		} else {
			this.$router.push({ name: 'NotFoundPage' });
		}
	},

	beforeDestroy() {
		if (!this.withoutClearItem) {
			this.set_item(null);
		}

		this.setup_navbar({});
	}
};

export default initPageDataMixin;
