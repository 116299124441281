<template functional>
	<div v-show="props.active" class="spinner"><div class="lds-dual-ring"></div></div>
</template>

<script>
export default {
	functional: true,
	props: {
		active: Boolean
	}
};
</script>
