import { getParentPageRoute } from '@/helpers';

const navigation = {
	methods: {
		changeRoute({ addToCurrent, parent, steps, path, history }) {
			// console.log('go to: ', path)

			if (path === '/logout') {
				this.$store.dispatch('auth/sign_out');
				// console.log('logout')
				return;
			}

			if (parent) {
				const parentPage = getParentPageRoute(this.$route.fullPath, steps);
				let route = parentPage;
				if (path) {
					route += `/${path}`;
				}
				this.$router.push(route);
				return;
			}

			if (history) {
				// const parentPage = getParentPageRoute(this.$route.fullPath, steps);
				this.$router.go(steps);
				return;
			}

			if (this.$route.fullPath !== path) {
				let route = path;

				if (addToCurrent) {
					route = this.$route.fullPath + path;
				}
				// console.log('nav: ', route)

				this.$router.push(route);
				// this.$emit('changeRoute', route);

				return;
			}
		}
	}
};

export default navigation;
