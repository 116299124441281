import Vue from 'vue';

// -------Element UI plugin---------
import Select from 'element-ui/lib/select';

// Vue.component('ElTable', () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'));
// Vue.component('ElTableColumn', () => import(/* webpackChunkName: "ElTableColumn"*/ 'element-ui/lib/table-column'));
Vue.component('ElInput', () =>
	import(/* webpackChunkName: "ElInput"*/ 'element-ui/lib/input')
);
Vue.component('ElCheckbox', () =>
	import(/* webpackChunkName: "ElCheckbox"*/ 'element-ui/lib/checkbox')
);
Vue.component('ElRadio', () =>
	import(/* webpackChunkName: "ElRadio"*/ 'element-ui/lib/radio')
);
// Vue.component('ElRadioGroup', () => import(/* webpackChunkName: "ElRadioGroup"*/ 'element-ui/lib/radio-group'));
// Vue.component('ElRadioButton', () => import(/* webpackChunkName: "ElRadioButton"*/ 'element-ui/lib/radio-button'));
// Vue.component('ElSelect', () => import(/* webpackChunkName: "ElSelect"*/ 'element-ui/lib/select'));
Vue.component('ElOption', () =>
	import(/* webpackChunkName: "ElOption"*/ 'element-ui/lib/option')
);
Vue.component('ElMenu', () =>
	import(/* webpackChunkName: "ElMenu"*/ 'element-ui/lib/menu')
);
Vue.component('ElSubmenu', () =>
	import(/* webpackChunkName: "ElSubmenu"*/ 'element-ui/lib/submenu')
);
Vue.component('ElMenuItemGroup', () =>
	import(/* webpackChunkName: "ElMenuItemGroup"*/ 'element-ui/lib/menu-item-group')
);
Vue.component('ElMenuItem', () =>
	import(/* webpackChunkName: "ElMenuItem"*/ 'element-ui/lib/menu-item')
);
Vue.component('ElButton', () =>
	import(/* webpackChunkName: "ElButton"*/ 'element-ui/lib/button')
);
Vue.component('ElPagination', () =>
	import(/* webpackChunkName: "ElPagination"*/ 'element-ui/lib/pagination')
);
Vue.component('ElForm', () =>
	import(/* webpackChunkName: "ElForm"*/ 'element-ui/lib/form')
);
Vue.component('ElFormItem', () =>
	import(/* webpackChunkName: "ElFormItem"*/ 'element-ui/lib/form-item')
);
// Vue.component('ElDialog', () => import(/* webpackChunkName: "ElDialog"*/ 'element-ui/lib/dialog'));
Vue.component('ElInputNumber', () =>
	import(/* webpackChunkName: "ElInputNumber"*/ 'element-ui/lib/input-number')
);
Vue.component('ElSwitch', () =>
	import(/* webpackChunkName: "ElSwitch"*/ 'element-ui/lib/switch')
);
// Vue.component('ElButtonGroup', () => import(/* webpackChunkName: "ElButtonGroup"*/ 'element-ui/lib/button-group'));
Vue.component('ElDatePicker', () =>
	import(/* webpackChunkName: "ElDatePicker"*/ 'element-ui/lib/date-picker')
);
// Vue.component('ElPopover', () => import(/* webpackChunkName: "ElPopover"*/ 'element-ui/lib/popover'));

// import 'element-ui/lib/theme-chalk/index.css';

// import 'element-ui/lib/theme-chalk/table.css';
// import 'element-ui/lib/theme-chalk/table-column.css';
import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/checkbox.css';
import 'element-ui/lib/theme-chalk/radio-group.css';
import 'element-ui/lib/theme-chalk/radio.css';
// import 'element-ui/lib/theme-chalk/radio-button.css';
import 'element-ui/lib/theme-chalk/select.css';
import 'element-ui/lib/theme-chalk/option.css';
import 'element-ui/lib/theme-chalk/menu.css';
import 'element-ui/lib/theme-chalk/submenu.css';
import 'element-ui/lib/theme-chalk/menu-item-group.css';
import 'element-ui/lib/theme-chalk/menu-item.css';
import 'element-ui/lib/theme-chalk/button.css';
import 'element-ui/lib/theme-chalk/pagination.css';
import 'element-ui/lib/theme-chalk/form.css';
import 'element-ui/lib/theme-chalk/form-item.css';
// import 'element-ui/lib/theme-chalk/dialog.css';
import 'element-ui/lib/theme-chalk/input-number.css';
import 'element-ui/lib/theme-chalk/switch.css';
// import 'element-ui/lib/theme-chalk/button-group.css';
import 'element-ui/lib/theme-chalk/date-picker.css';
// import 'element-ui/lib/theme-chalk/popover.css';
import 'element-ui/lib/theme-chalk/icon.css';

import langEn from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(langEn);

Select.computed.readonly = function() {
	const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));

	return !(this.filterable || this.multiple || !isIE) && !this.visible;
};
Vue.use(Select);

import SimpleSpinner from '@/components/SimpleSpinner.vue';
Vue.component('SimpleSpinner', SimpleSpinner);

import SearchBar from '@/layout/dashboard/components/SearchBar.vue';
Vue.component('SearchBar', SearchBar);

import VueElementLoading from 'vue-element-loading';
Vue.component('VueElementLoading', VueElementLoading);

// Plugins
import globalMethods from './services/globalMethods';
Vue.use(globalMethods);
