import {
	getResponseMessage,
	isSuccessStatus,
	handleError,
	isPrevent,
	getResponseValue,
	getResultMessage
} from '@/services/api/api_helpers';
import { Notification } from 'element-ui';
import { api } from '@/services/api';

const getOptions = payload => {
	const { notNotify, notSetToStore, notLoading, notNotifyError } = payload;

	return {
		loading: !notLoading,
		notify: !notNotify,
		toStore: !notSetToStore,
		notifyError: !notNotifyError
	};
};

const fetch_items = ({ commit }, url, payload = {}) => {
	if (isPrevent()) return;
	const { loading, toStore, notifyError } = getOptions(payload);

	if (loading) commit('SET_STATUS_LOADING', true);

	return new Promise((resolve, reject) => {
		api('GET', url, payload)
			.then(response => {
				if (isSuccessStatus(response)) {
					try {
						if (toStore) {
							commit('SET_STATE', {
								stateProp: 'itemsList',
								value: getResponseValue(response, payload)
							});

							if (response.data.meta) {
								commit('SET_STATE', {
									stateProp: 'fetchedMeta',
									value: response.data.meta
								});
							}
						}

						resolve(response.data);
					} catch (e) {
						console.warn(e);
					}
				} else {
					const message = getResponseMessage(response);
					reject(response);
					Notification.error({
						title: `Error`,
						message: message || 'wrong response status',
						duration: 0
					});
				}
				// setTimeout(() => {
				if (loading) commit('SET_STATUS_LOADING', false);
				// }, 2000);
			})
			.catch(error => {
				handleError(error, {
					commit: commit,
					reject: reject,
					loading: loading,
					notify: notifyError
				});
			});
	});
};

const save_data = ({ commit }, payloadUrl, payload = {}) => {
	if (isPrevent()) return;
	const { loading, notify, toStore, notifyError } = getOptions(payload);

	if (loading) commit('SET_STATUS_SAVING', true);

	let options;
	if (payload.data.id) {
		options = {
			method: 'PUT',
			url: `${payloadUrl}/${payload.data.id}`,
			thisResultMessage: 'сохранен',
			updateRoute: false
			// returnParentRoute: true
		};
	} else {
		options = {
			method: 'POST',
			url: payloadUrl,
			thisResultMessage: 'создан',
			updateRoute: true
			// returnParentRoute: true
		};
	}
	const { method, url, thisResultMessage, updateRoute } = options;
	// console.log(options)
	return new Promise((resolve, reject) => {
		api(method, url, payload)
			.then(response => {
				if (isSuccessStatus(response)) {
					try {
						const { stateProp, resultMessage } = payload;
						if (toStore) {
							commit('SET_STATE', {
								stateProp: stateProp,
								value: response.data.data
							});
						}
						resolve({ data: response.data, updateRoute: updateRoute });
						if (notify)
							Notification.success({
								title: `Success`,
								message: resultMessage
									? resultMessage.text
									: `Элемент ${thisResultMessage}`
							});
					} catch (e) {
						console.warn(e);
					}
				} else {
					const message = getResponseMessage(response);
					reject(response);
					Notification.error({
						title: `Error`,
						message: message || 'wrong response status',
						duration: 0
					});
				}

				if (loading) commit('SET_STATUS_SAVING', false);
			})
			.catch(error => {
				// console.log('catch', reject)
				handleError(error, {
					commit: commit,
					reject: reject,
					loading: loading,
					notify: notifyError
				});
			});
	});
};

const delete_item = ({ commit }, payloadUrl, payload = {}) => {
	if (isPrevent()) return;
	const { loading, notify, notifyError } = getOptions(payload);

	if (!loading) commit('SET_STATUS_SAVING', true);

	let options;
	if (payload.data.id) {
		options = {
			url: `${payloadUrl}/${payload.data.id}`,
			resultMessage: 'item'
		};
	} else if (payload.data.ids) {
		options = {
			url: payloadUrl,
			resultMessage: 'items',
			payloadData: payload
		};
	}
	const { url, payloadData, resultMessage } = options;
	// console.log(url, payloadData, notify, notifyError, resultMessage)
	return new Promise((resolve, reject) => {
		api('DELETE', url, payloadData)
			.then(response => {
				// console.log(isSuccessStatus(response));
				if (isSuccessStatus(response)) {
					try {
						resolve(response.data);
						if (notify)
							Notification.success({
								title: `Success`,
								message: `${resultMessage} deleted`
							});
					} catch (e) {
						console.log(e);
					}
				}

				if (loading) commit('SET_STATUS_SAVING', false);
			})
			.catch(error => {
				handleError(error, {
					commit: commit,
					reject: reject,
					loading: loading,
					notify: notifyError
				});
			});
	});
};

const multipurpose_response = ({ commit, dispatch }, url, payload = {}) => {
	if (isPrevent()) return;
	const { loading, toStore, notify, notifyError } = getOptions(payload);
	const { method, loadingProp, withoutDataCheck } = payload;

	if (loading) {
		if (loadingProp) {
			commit('SET_STATE', { stateProp: loadingProp, value: true });
		} else {
			commit('SET_STATUS_LOADING', true);
		}
	}

	return new Promise((resolve, reject) => {
		api(method, url, payload)
			.then(response => {
				// console.time('result from back')
				if (isSuccessStatus(response, withoutDataCheck)) {
					try {
						const {
							stateProp,
							nestedStateProp,
							action,
							resultMessage,
							concatData
						} = payload;
						// console.log(stateProp)
						const value = getResponseValue(response, payload);

						if (toStore) {
							if (nestedStateProp) {
								commit('SET_NESTED_STATE', {
									stateProp: nestedStateProp,
									value: value,
									concatData: concatData
								});
							} else {
								commit('SET_STATE', {
									stateProp: stateProp,
									value: value,
									concatData: concatData
								});
							}
						}

						if (action) {
							dispatch(action);
						}
						// console.log(response)
						resolve(value);
						const message = getResultMessage(resultMessage, response.data.data);
						if (notify)
							Notification.success({
								title: `Success`,
								message: message,
								duration: 2500
							});
					} catch (e) {
						console.warn(e);
					}
				} else {
					const message = getResponseMessage(response);
					reject(response);
					Notification.error({
						title: `Error`,
						message: message || 'wrong response status',
						duration: 0
					});
				}

				if (loading) {
					if (loadingProp) {
						commit('SET_STATE', { stateProp: loadingProp, value: false });
					} else {
						commit('SET_STATUS_LOADING', false);
					}
				}
			})
			.catch(error => {
				// console.log('catch', reject)
				handleError(error, {
					commit: commit,
					reject: reject,
					loading: loading,
					notify: notifyError
				});
			});
	});
};

export {
	fetch_items,
	/*fetch_data,*/ save_data,
	delete_item,
	multipurpose_response
};
